import useLoader from '@lib/loaders/hooks/useLoader';
import LOG from '@lib/logger';
import { IFilter } from '@lib/tables/table/filters';
import { debounce } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import { IPost } from '@/bundles/blog/model/post';
import paths from '@/bundles/blog/paths';
import EntityManager from '@/bundles/core/services/entity-manager';

import { PostListProps } from '../index';
import useColumns from './useColumns';
import useItemFilters from './useItemFilters';

export type usePostListProps = {} & PostListProps;

export default function usePostList(props: usePostListProps) {
  const postManager = useMemo(() => new EntityManager<IPost>('/', '/api/proxy/posts'), []);
  const loader = useLoader();
  const router = useRouter();
  const { filters } = useItemFilters(props);

  const onRemove = async (item: IPost) => {
    loader.setLoading(true);
    try {
      if (!item.id) return;
      await postManager.delete(item.id);
      router.refresh();
    } catch (error) {
      LOG.error({ msg: 'PostList:onRemove', error });
    } finally {
      loader.setLoading(false);
    }
  };

  const { columns } = useColumns({ ...props, onRemove });

  const defaultFilterValues = useMemo(
    () => ({
      status: '',
      postType: '',
    }),
    [],
  );

  const onSearchDebounced = useCallback(
    debounce((searchKey: string) => {
      applyFilters({ name: searchKey });
    }, 1000),
    [],
  );

  const onSearch = (searchKey?: string, filter?: IFilter) => {
    if (searchKey !== undefined && !filter) onSearchDebounced(searchKey);
  };

  const applyFilters = (filters: Record<string, string>) => {
    const query = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => value && query.append(key, value));
    const url = paths.index.concat(`?filters=[${query.toString()?.replaceAll('=', ':').replaceAll('&', ',')}]`);
    LOG.debug({ msg: 'PostList:applyFilters', url });
    router.push(url);
  };

  return { router, filters, columns, defaultFilterValues, onSearchDebounced, onSearch, applyFilters };
}
