import { ListProps } from '@/bundles/core/types';
import useColumns from '@/bundles/products/components/client/product-list/hooks/useColumns';
import useItemFilters from '@/bundles/products/components/client/product-list/hooks/useItemFilters';
import { ICategory } from '@/bundles/products/model/category';
import { ICollection } from '@/bundles/products/model/collection';
import { ILine } from '@/bundles/products/model/line';
import { IProduct } from '@/bundles/products/model/product';
import paths from '@/bundles/products/paths';
import ProductManager from '@/bundles/products/services/product-manager';
import useLoader from '@lib/loaders/hooks/useLoader';
import LOG from '@lib/logger';
import { IFilter } from '@lib/tables/table/filters';
import { debounce, updateQueryStringParameter } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';

export type ProductListProps = {
  collections: ICollection[];
  lines: ILine[];
  categories: ICategory[];
} & ListProps<IProduct>;

export default function useProductList(props: ProductListProps) {
  const productManager = useMemo(() => new ProductManager('/', '/api/proxy/products'), []);
  const loader = useLoader();
  const router = useRouter();
  const { filters } = useItemFilters({ ...props });

  const onRemove = async (item: IProduct) => {
    loader.setLoading(true);
    try {
      if (!item.id) return;
      await productManager.delete(item.id);
      router.refresh();
    } catch (error) {
      LOG.error({ msg: 'ProductList.onRemove', error });
    } finally {
      loader.setLoading(false);
    }
  };

  const { columns } = useColumns({ ...props, onRemove });

  const defaultSearchFilterValues = useMemo(() => ({
    name: '',
    sku: ''
  }), []);

  const defaultFilterValues = useMemo(() => ({
    status: '',
    'collection:name': '',
    'line:name': '',
    'category:name': ''
  }), []);

  const onSearchDebounced = useCallback(debounce((searchKey: string) => {
    applyFilters({ name: searchKey });
  }, 1000), []);

  const onSearch = (searchKey?: string, filter?: IFilter) => {
    if (searchKey !== undefined && !filter) onSearchDebounced(searchKey);
  };

  const applyFilters = (filters: Record<string, string>) => {
    const query = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value) query.append(key, value);
    });
    const filterValue = `[${query.toString()?.replaceAll('=', ':').replaceAll('&', ',')}]`;
    let url = updateQueryStringParameter(window?.location?.href, 'filters', filterValue);
    url = updateQueryStringParameter(url, 'page', '1');
    LOG.debug({ msg: 'ProductList.applyFilters', url });
    router.push(url);
  };

  const onChangePage = (page: unknown) => {
    const url = updateQueryStringParameter(window?.location?.href, 'page', page as string);
    LOG.debug({ msg: 'ProductList.onGoToCreate', url });
    router.push(url);
  };

  return { router, filters, columns, defaultFilterValues, defaultSearchFilterValues, onSearchDebounced, onSearch, applyFilters, onChangePage };
}
