import { useMemo } from 'react';

import { UserListProps } from './useUserList';

export type useItemFiltersProps = {} & UserListProps;

const useItemFilters = (props: useItemFiltersProps) => {
  const filters = useMemo(
    () => [
      {
        type: 'dropdown',
        filterKey: 'status',
        name: 'Estado',
        filters: [
          { label: 'Pendiente', value: 'pendiente' },
          { label: 'Suspendido', value: 'suspendido' },
          { label: 'Activo', value: 'activo' },
        ],
      },
      {
        type: 'dropdown',
        filterKey: 'role',
        name: 'Role',
        filters: [
          { label: 'Usuario', value: 'usuario' },
          { label: 'Admin', value: 'admin' },
        ],
      },
    ],
    [],
  );
  return { filters };
};

export default useItemFilters;
