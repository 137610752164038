import useLoader from '@lib/loaders/hooks/useLoader';
import LOG from '@lib/logger';
import { IFilter } from '@lib/tables/table/filters';
import { debounce } from '@lib/utils';
import { useRouter } from 'next/navigation';
import { useCallback, useMemo } from 'react';

import { ListProps } from '@/bundles/core/types';
import ProductManager from '@/bundles/products/services/product-manager';
import { IUser } from '@/bundles/users/model/user';
import paths from '@/bundles/users/paths';

import useColumns from './useColumns';
import useItemFilters from './useItemFilters';

export type UserListProps = {} & ListProps<IUser>;

export default function useUserList(props: UserListProps) {
  const customerManager = useMemo(() => new ProductManager('/', '/api/proxy/users'), []);
  const loader = useLoader();
  const router = useRouter();
  const { filters } = useItemFilters(props);

  const onRemove = async (item: IUser) => {
    loader.setLoading(true);
    try {
      if (!item.id) return;
      await customerManager.delete(item.id);
      router.refresh();
    } catch (error) {
      LOG.error({ msg: 'UserList:onRemove', error });
    } finally {
      loader.setLoading(false);
    }
  };

  const { columns } = useColumns({ ...props, onRemove });

  const defaultFilterValues = useMemo(
    () => ({
      status: '',
      role: '',
    }),
    [],
  );

  const onSearchDebounced = useCallback(
    debounce((searchKey: string) => {
      applyFilters({ email: searchKey });
    }, 1000),
    [],
  );

  const onSearch = (searchKey?: string, filter?: IFilter) => {
    if (searchKey !== undefined && !filter) onSearchDebounced(searchKey);
  };

  const applyFilters = (filters: Record<string, string>) => {
    const query = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => value && query.append(key, value));
    const url = paths.index.concat(`?filters=[${query.toString()?.replaceAll('=', ':').replaceAll('&', ',')}]`);
    LOG.debug({ msg: 'UserList:applyFilters', url });
    router.push(url);
  };

  return { router, filters, columns, defaultFilterValues, onSearchDebounced, onSearch, applyFilters };
}
