'use client';

import { ColumnDef } from '@lib/devias/components/core/data-table';
import TableFormatter from '@lib/tables/table/formatters';
import ActionFormatter from '@lib/tables/table/formatters/action';
import ImageFormatter from '@lib/tables/table/formatters/image';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { Link as MLink } from '@mui/material';
import Chip from '@mui/material/Chip';
import { Box } from '@mui/system';
import { CheckCircle as CheckCircleIcon } from '@phosphor-icons/react/dist/ssr/CheckCircle';
import { Clock as ClockIcon } from '@phosphor-icons/react/dist/ssr/Clock';
import { PencilSimple as PencilSimpleIcon } from '@phosphor-icons/react/dist/ssr/PencilSimple';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';

import { IProduct } from '@/bundles/products/model/product';

import { ProductListProps } from './useProductList';

const useColumns = (props: ProductListProps) => {
  const router = useRouter();

  const columns = useMemo(
    () =>
      [
        {
          formatter: (row: any) => {
            return (
              <ImageFormatter row={row} index="thumbnail">
                <MLink href={`/admin/products/${row.id}`} component={Link} color="text.primary" sx={{ whiteSpace: 'nowrap' }} underline="none" variant="subtitle2">
                  {row.name}
                </MLink>
                <Box>
                  <span>Fecha: </span>
                  <span>{new Date(row.createdAt).toLocaleString()}</span>
                </Box>
                <Box>
                  <span>SKU: </span>
                  <span>{row.sku}</span>
                </Box>
              </ImageFormatter>
            );
          },
          name: 'Producto',
          width: '100%',
        },
        {
          formatter: (row) => <TableFormatter row={row?.category} index="name" />,
          name: 'Categoría',
          width: '100px',
        },
        {
          formatter: (row) => <TableFormatter row={row?.line} index="name" />,
          name: 'Línea',
          width: '100px',
        },
        {
          formatter: (row) => <TableFormatter row={row?.collection} index="name" />,
          name: 'Colección',
          width: '100px',
        },
        {
          formatter: (row: any) => {
            const mapping = {
              pendiente: { label: 'Pendiente', icon: <ClockIcon color="var(--mui-palette-secondary-main)" /> },
              pausado: { label: 'Pausado', icon: <ClockIcon color="var(--mui-palette-secondary-main)" /> },
              publicado: {
                label: 'Publicado',
                icon: <CheckCircleIcon color="var(--mui-palette-success-main)" weight="fill" />,
              },
            } as const;
            const { label, icon } = mapping[row.status as 'pendiente'] ?? { label: 'Unknown', icon: null };

            return <Chip icon={icon} label={label} size="small" variant="outlined" />;
          },
          name: 'Estado',
          width: '100px',
        },
        {
          formatter: (row) => <ActionFormatter Icon={PencilSimpleIcon} onClick={() => router.replace(`/admin/products/${row.id}`)} />,
          name: 'Edit',
          hideName: true,
          width: '50px',
          align: 'right',
        },
        {
          formatter: (arg) => (
            <ActionFormatter
              onClick={async () => {
                await props.onRemove?.(arg);
                await props.onRefresh?.();
              }}
              Icon={DeleteOutlineIcon}
            />
          ),
          name: 'Delete',
          hideName: true,
          width: '50px',
          align: 'right',
        },
      ] satisfies ColumnDef<IProduct>[],
    [],
  );

  return { columns };
};

export default useColumns;
