import { useMemo } from 'react';

import { ProductListProps } from '@/bundles/products/components/client/product-list/hooks/useProductList';
import { ICategory } from '@/bundles/products/model/category';
import { ICollection } from '@/bundles/products/model/collection';
import { ILine } from '@/bundles/products/model/line';

export type useItemFiltersProps = {
  collections: ICollection[];
  lines: ILine[];
  categories: ICategory[];
} & ProductListProps;

const useItemFilters = (props: useItemFiltersProps) => {
  const filters = useMemo(
    () => [
      {
        type: 'dropdown',
        filterKey: 'status',
        name: 'Estado',
        filters: [
          { label: 'Pendiente', value: 'pendiente' },
          { label: 'Pausado', value: 'pausado' },
          { label: 'Publicado', value: 'publicado' },
        ],
      },
      {
        type: 'dropdown',
        name: 'Colección',
        filterKey: 'collection:name',
        filters: Array.isArray(props.collections) ? props.collections?.map((item) => ({ label: item.name, value: item.name })) : [],
      },
      {
        type: 'dropdown',
        filterKey: 'line:name',
        name: 'Línea',
        filters: Array.isArray(props.lines) ? props.lines?.map((item) => ({ label: item.name, value: item.name })) : [],
      },
      {
        type: 'dropdown',
        filterKey: 'category:name',
        name: 'Categoría',
        filters: Array.isArray(props.categories) ? props.categories?.map((item) => ({ label: item.name, value: item.name })) : [],
      }
    ],
    [props.collections, props.lines, props.categories],
  );
  return { filters };
};

export default useItemFilters;
