'use client';

import React, { FC } from 'react';

import { IPost } from '@/bundles/blog/model/post';
import ItemList from '@/bundles/core/components/client/item-list';
import { ListProps } from '@/bundles/core/types';

import usePostList from './hooks/usePostList';

export type PostListProps = {} & ListProps<IPost>;

const PostList: FC<PostListProps> = (props) => {
  const config = usePostList(props);

  return (
    <ItemList
      items={props.items}
      columns={config.columns}
      filters={config.filters}
      applyFilters={config.applyFilters}
      defaultFilterValues={config.defaultFilterValues}
      page={props.page}
      count={props.count}
      searchOptions={{
        title: 'Buscar en artículos',
        onChange: config.onSearch,
      }}
    />
  );
};

export default PostList;
