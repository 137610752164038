import { useMemo } from 'react';

import { CustomerListProps } from '@/bundles/customers/components/client/customer-list/hooks/useCustomerList';

export type useItemFiltersProps = {} & CustomerListProps;

const useItemFilters = (props: useItemFiltersProps) => {
  const filters = useMemo(
    () => [
      {
        type: 'dropdown',
        filterKey: 'status',
        name: 'Estado',
        filters: [
          { label: 'Pendiente', value: 'pendiente' },
          { label: 'Suspendido', value: 'suspendido' },
          { label: 'Activo', value: 'activo' },
        ],
      },
      {
        type: 'dropdown',
        filterKey: 'postType',
        name: 'Tipo de publicación',
        filters: [{ label: 'FAQ', value: 'faq' }],
      },
    ],
    [],
  );
  return { filters };
};

export default useItemFilters;
