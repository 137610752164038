'use client';

import { FC } from 'react';

import ItemList from '@/bundles/core/components/client/item-list';

import useProductList, { ProductListProps } from './hooks/useProductList';

const ProductList: FC<ProductListProps> = (props) => {
  const config = useProductList(props);

  return (
    <ItemList
      items={props.items}
      columns={config.columns}
      filters={config.filters}
      applyFilters={config.applyFilters}
      defaultFilterValues={config.defaultFilterValues}
      onChangePage={config.onChangePage}
      page={props.page}
      count={props.count}
      searchOptions={{
        title: 'Buscar en productos',
        onChange: config.onSearch,
      }}
    />
  );
};

export default ProductList;
