'use client';

import { FC } from 'react';

import ItemList from '@/bundles/core/components/client/item-list';

import useCustomerList, { CustomerListProps } from './hooks/useCustomerList';

const CustomerList: FC<CustomerListProps> = (props) => {
  const config = useCustomerList(props);

  return (
    <ItemList
      items={props.items}
      columns={config.columns}
      filters={config.filters}
      applyFilters={config.applyFilters}
      defaultFilterValues={config.defaultFilterValues}
      page={props.page}
      count={props.count}
      searchOptions={{
        title: 'Buscar en consumidores',
        onChange: config.onSearch,
      }}
    />
  );
};

export default CustomerList;
